<template>
  <div class="page-container">
    <div class="section-top">
      <div class="title-holder">
        <a href="/" class="link-back">
          <i class="icon icon-arrow-back"></i>
        </a>
        <h1 class="title">
          <span
            class="title-item"
            v-text="$ml.with('VueJS').get('personalTitle')"
          ></span>
        </h1>
      </div>
    </div>
    <div class="section-main user-page">
      <div class="user-info-holder">
        <div class="request-row">
          <span
            class="request-name"
            v-text="$ml.with('VueJS').get('labelFullName')"
            >ПІБ</span
          >
          <span class="request-val">{{ userName }}</span>
        </div>
        <div class="request-row">
          <span
            class="request-name"
            v-text="$ml.with('VueJS').get('labelEmail')"
            >E-mail</span
          >
          <span class="request-val">{{ userEmail }}</span>
        </div>
        <div class="request-row">
          <span
            class="request-name"
            v-text="$ml.with('VueJS').get('labelPhone')"
            >Телефон</span
          >
          <span class="request-val">{{ userPhone }}</span>
        </div>
        <div class="request-row">
          <span
            class="request-name"
            v-text="$ml.with('VueJS').get('labelRegion')"
            >Регіон</span
          >
          <span class="request-val">{{ userRegion }}</span>
        </div>
        <div class="request-row">
          <span
            class="request-name"
            v-text="$ml.with('VueJS').get('labelNumber')"
            >ЄДРПОУ</span
          >
          <span class="request-val">{{ userNumber }}</span>
        </div>
        <div class="request-row">
          <span
            class="request-name"
            v-text="$ml.with('VueJS').get('labelCompany')"
            >Назва підприємства</span
          >
          <span class="request-val">{{ userCompany }}</span>
        </div>
        <div class="user-action-holder">
          <div class="user-action-row">
            <a href="#/PersonalForm" class="link-red">
              <i class="icon icon-edit">icon</i>
              <span>{{ $ml.with("VueJS").get("personal1") }}</span>
            </a>
          </div>
          <div class="user-action-row">
            <a href="#/PersonalPassword" class="link-red">
              <i class="icon icon-change-pass">icon</i>
              <span>{{ $ml.with("VueJS").get("personal2") }}</span>
            </a>
          </div>
          <div class="user-action-row">
            <a
              href="/#/Login"
              v-on:click="signOut()"
              class="link-red"
              v-text="$ml.with('VueJS').get('logout')"
              >{{ $ml.with("VueJS").get("personal3") }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_USERS } from "../../helpers/requests";

export default {
  data() {
    return {
      resultUser: {},
      // title: 'Личный кабинет',
      userId: localStorage.getItem("userId"),
      userName: localStorage.getItem("userName"),
      userPhone: localStorage.getItem("userPhone"),
      userEmail: localStorage.getItem("userEmail"),
      userRegion: localStorage.getItem("userRegion"),
      userNumber: localStorage.getItem("userNumber"),
      userCompany: localStorage.getItem("userCompany"),
    };
  },
  mounted() {
    this.loadJsonUser();

    let profile = window.profile;

    if (profile) {
      this.userName = profile.userName;
      this.userPhone = profile.userPhone;
      this.userEmail = profile.userEmail;
      this.userRegion = profile.userRegion;
      this.userNumber = profile.userNumber;
      this.userCompany = profile.userCompany;
    }
    this.isLang(localStorage.activeItem);
  },
  methods: {
    async loadJsonUser() {
      await this.users
        .get()
        .then((response) => response.json())
        .then((response) => {
          this.resultUser = response;
        });
      if (typeof resultUser.can_use_app === 'boolean' && !this.resultUser.can_use_app) {
        localStorage.clear();
        return;
      }
      this.userEmail = this.resultUser.email;
      this.userName = this.resultUser.name;
      this.userPhone = this.resultUser.phone;
      this.userRegion = this.resultUser.region.name;
      this.userNumber = this.resultUser.egrpou;
      this.userCompany = this.resultUser.company_name;
      this.saveLocalStorage(
        // this.resultUser.id,
        this.resultUser.name,
        this.resultUser.email,
        this.resultUser.phone,
        this.resultUser.region.name,
        this.resultUser.egrpou,
        this.resultUser.company_name
      );
      this.loading = false;
    },
    saveLocalStorage(
      userName,
      userEmail,
      userPhone,
      userRegion,
      userNumber,
      userCompany
    ) {
      // localStorage.setItem('userId', userId)
      localStorage.setItem("userName", userName);
      localStorage.setItem("userPhone", userPhone);
      localStorage.setItem("userEmail", userEmail);
      localStorage.setItem("userRegion", userRegion);
      localStorage.setItem("userNumber", userNumber);
      localStorage.setItem("userCompany", userCompany);
    },
    getLocalStorage() {
      // localStorage.getItem('userName')
    },
    signOut() {
      // this.deleteCookies()
      document.cookie = "userToken=;max-age=-1";
      localStorage.userToken = "";
      document.cookie = "userId=;max-age=-1";
      localStorage.userId = "";
    },
    // deleteCookies () {
    //   let cookies = document.cookie.split(';')
    //   for (let i = 0; i < cookies.length; i++) {
    //     let cookie = cookies[i]
    //     let eqPos = cookie.indexOf('=')
    //     let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    //     document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
    //   }
    // }
    isLang: function (lang) {
      if (lang === "0") {
        this.$ml.change("ru");
      } else if (lang === "1") {
        this.$ml.change("ua");
      }
    },
  },
  async created() {
    const response = await GET_USERS(localStorage.userId);
    this.users = await response.json();
  },
  watch: {
    userName(newName) {
      localStorage.userName = newName;
    },
    userEmail(newEmail) {
      localStorage.userEmail = newEmail;
    },
    userPhone(newPhone) {
      localStorage.userPhone = newPhone;
    },
    userRegion(userRegion) {
      localStorage.userRegion = this.userRegion;
    },
    userNumber(userNumber) {
      localStorage.userNumber = userNumber;
    },
    userCompany(userCompany) {
      localStorage.userCompany = userCompany;
    },
  },
};
</script>
